<template>
    <v-container v-if="getCart!=null" :class="{wrapper: cartItems.length === 0,  cart: cartItems.length !== 0}">

        <v-row justify="center" align="center">
            <v-col :cols="12" :md="userIsLoggedIn ? 8 : 12">
                <v-card :class="{'elevation-1':!preorder,'elevation-1':preorder}">
                    <v-card-text>
                        <v-card class="elevation-0" :loading="loading && showLoading">
                            <v-card-title>
                                پیش فاکتور
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="elevation-1">

                                <v-data-table :items="itemsArray" hide-default-footer :headers="headers">

                                    <template v-slot:item.item.title="{item}">
                                        <v-icon class="green--text">fas fa-heartbeat</v-icon>
                                        {{item.item.title}}
                                    </template>

                                    <template v-slot:item.price="{item}">
                                        {{item.price | withCurrency(getCart.currency)}}
                                    </template>

                                    <template v-slot:item.final_price="{item}">
                                        {{item.qty * item.price | withCurrency(getCart.currency)}}
                                    </template>

                                    <template v-slot:item.qty="{item}">
                                        <v-icon @click="addToCart(item)" small>mdi-plus</v-icon>
                                        <span class="mx-2 font-weight-bold">{{ item.qty }}</span>
                                        <v-icon @click="removeItem(item)" small>mdi-minus</v-icon>
                                    </template>
                                </v-data-table>

                                <v-data-table
                                        dense
                                        :headers="invoiceHeaders"
                                        :items="invoiceItems"
                                        hide-default-footer
                                        mobile-breakpoint="300"
                                >
                                    <template v-slot:item="{item}">
                                        <tr v-if="item.show">

                                            <td class="text-left"
                                                :class="{'font-weight-bold final-price-item':item.final}">
                                                {{item.title}}
                                            </td>

                                            <td :class="{'font-weight-bold final-price-item':item.final}">
                                            </td>
                                            <td :class="{'font-weight-bold final-price-item':item.final}">
                                            </td>
                                            <td class="text-center"
                                                :class="{'font-weight-bold final-price-item':item.final}">

                                                {{item.price | withCurrency(getCart.currency)}}
                                            </td>
                                        </tr>
                                    </template>

                                </v-data-table>

                            </v-card-text>
                            <v-row dense justify="center">
                                <v-col cols="12" class="text-center">
                                    <!--<v-data-table-->
                                    <!--dense-->
                                    <!--:headers="invoiceHeaders"-->
                                    <!--:items="invoiceItems"-->
                                    <!--hide-default-footer-->
                                    <!--mobile-breakpoint="300"-->
                                    <!--&gt;-->
                                    <!--<template v-slot:item="{item}">-->
                                    <!--<tr v-if="item.show">-->
                                    <!--<td>-->
                                    <!--</td>-->
                                    <!--<td class="text-left">-->
                                    <!--{{item.title}}-->
                                    <!--</td>-->
                                    <!--<td>-->
                                    <!--</td>-->

                                    <!--<td class="text-center">-->

                                    <!--{{item.price | withCurrency(getCart.currency)}}-->
                                    <!--</td>-->
                                    <!--</tr>-->
                                    <!--</template>-->

                                    <!--</v-data-table>-->
                                </v-col>
                            </v-row>
                            <v-card-text>
                                <v-row dense justify="center" align="center">
                                    <v-col cols="12" sm="8" md="8">
                                        <v-text-field
                                                @click:clear="voucherCleared"
                                                v-if="discount && userIsLoggedIn"
                                                outlined
                                                clearable
                                                v-model="voucherCode"
                                                label="کد تخفیف"
                                                single-line
                                                :messages="validVoucher != 'unknown' ? message:''"
                                                :error="validVoucher=='invalid'"
                                                :success="validVoucher=='valid'"
                                        >
                                            <template v-slot:append>
                                                <v-btn class="mb-3 info elevation-0" @click="validateVoucher">اعمال
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                    </v-col>

                                </v-row>
                                <v-row justify="center" align="center">
                                    <v-col cols="12" v-if="userIsLoggedIn " class="text-center ">
                                        <template v-if="canUseWallet">
                                            <v-card dense class="pa-0 ma-0 elevation-1 info">
                                                <v-card-text class="pa-0 ma-0">
                                                    <div style="display: inline-block" class="white--text">
                                                        {{walletMessage}}
                                                    </div>
                                                    <div style="display: inline-block" class="">
                                                        <v-switch v-model="applyMonetaryWallet"
                                                                  inset
                                                                  :readonly="isInDebt"
                                                                  color="white"
                                                                  class="mx-2 "></v-switch>
                                                    </div>
                                                    <div style="display: inline-block" class="white--text ">
                                                        مبلغ :
                                                        {{absNum(differencialPrice) | withCurrency(getCart.currency)}}
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>


                        <v-row v-if="!userIsLoggedIn">
                            <v-col cols="12">
                                <v-alert dense type="info">برای خرید ابتدا وارد شوید یا ثبت نام کنید</v-alert>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4" v-if="userIsLoggedIn" justify="center" align="center" class="pa-0 ma-0">
                <v-card>
                    <v-card-text>

                        <v-data-table
                                :headers="smallInvoiceHeaders"
                                :items="invoiceItems"
                                hide-default-footer
                                mobile-breakpoint="300"
                        >
                            <template v-slot:item="{item}">
                                <tr v-if="item.show">
                                    <td class="text-right " :class="{'font-weight-bold final-price-item':item.final}">
                                        {{item.title}}
                                    </td>
                                    <td class="text-center" :class="{'font-weight-bold final-price-item':item.final}">

                                        {{item.price | withCurrency(getCart.currency)}}
                                    </td>
                                </tr>
                            </template>

                        </v-data-table>
                    </v-card-text>
                    <v-card-text>
                        <v-row dense>

                            <v-dialog v-model="showOfflinePaymentDialog" max-width="800" scrollable
                                      v-if="userIsLoggedIn"
                            >
                                <ClientOfflinePayment @done="savingOfflineDone"
                                                      :preorder="preorder"
                                                      :cart="getCart"
                                                      :show="showOfflinePaymentDialog"
                                                      @close="showOfflinePaymentDialog=false"
                                                      :coupon_code="voucherCode"></ClientOfflinePayment>
                            </v-dialog>

                            <v-col cols="12" v-if="userIsLoggedIn" class="text-right">
                                <!--<v-checkbox v-model="privacyPolicy" style="float:right" class="text-center">-->
                                <!--<template v-slot:label>-->
                                <!--<div>-->
                                <!--<a-->
                                <!--target="_blank"-->
                                <!--style="text-decoration: none;"-->
                                <!--href="https://simiaroom.com/%D8%B4%D8%B1%D8%A7%DB%8C%D8%B7-%D8%B6%D9%88%D8%A7%D8%A8%D8%B7-%D9%85%D8%B4%D8%A7%D9%88%D8%B1%D9%87/"-->
                                <!--@click.stop-->
                                <!--&gt;-->
                                <!--شرایط و ضوابط-->
                                <!--</a>-->
                                <!--را خوانده و میپذیرم.-->
                                <!--</div>-->
                                <!--</template>-->
                                <!--</v-checkbox>-->
                                <template>
                                    <div style="clear: both" class="text-center">
                                        <v-btn class="elevation-0 " height="60" :disabled="loading"
                                               color="green white--text"
                                               block
                                               @click="sendOrder" style="position:relative;">
                                            پرداخت

                                            <v-avatar size="40" class="absolute-payment-img">
                                                <img :src="paymentMethodURL" alt="">
                                            </v-avatar>
                                        </v-btn>
                                        <div></div>
                                        <v-btn class="elevation-0 mt-2 ml-2" small @click="showOfflinePayment">
                                            پرداخت آفلاین
                                        </v-btn>
                                    </div>
                                </template>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


    </v-container>
</template>

<script>
    /*eslint-disable*/
    import {EventBus} from "../../event-bus";
    import {mapGetters} from "vuex";
    import ClientOfflinePayment from '@/components/clients/ClientOfflinePayment';


    export default {
        name: 'clientCart',
        components: {
            ClientOfflinePayment
        },
        props: ['discount', 'price', 'remove', 'preorder', 'preorderCoupon', 'showLoading'],

        data() {
            return {
                headers: [
                    {text: 'آیتم', value: 'item.title', sortable: false},
                    {text: 'قیمت واحد', value: 'price', sortable: false},
                    {text: 'تعداد', value: 'qty', sortable: false},
                    {text: 'قیمت کل', value: 'final_price', sortable: false},
                ],
                // invoiceItems: [],
                smallInvoiceHeaders: [
                    {
                        text: '',
                        align: 'start',
                        sortable: false,
                        value: 'title',
                    },
                    {
                        text: 'مبلغ',
                        align: 'center',
                        sortable: false,
                        value: 'price',
                    }
                ],
                invoiceHeaders: [
                    {
                        text: '',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    {
                        text: '',
                        align: 'end',
                        sortable: false,
                        value: 'title',
                    },
                    {
                        text: '',
                        align: 'end',
                        sortable: false,
                        value: 'title',
                    },
                    {
                        text: 'مبلغ',
                        align: 'center',
                        sortable: false,
                        value: 'price',
                    },
                ],
                applyMonetaryWallet: true,
                privacyPolicy: true,
                sendingOrder: false,
                showOfflinePaymentDialog: false,
                requesting: false,
                voucherCode: "",
                validVoucher: 'unknown',
                message: "",
                voucher: {},
                // loading: false,
                wallet: null
            }
        },
        beforeDestroy() {
            EventBus.$off('sendOrderEvent', this.sendOrder);
        },
        mounted() {
            // if (this.preorderCoupon != null) {
            //     this.voucherCode = this.preorderCoupon;
            //     this.validateVoucher();
            // }
        },
        created() {
            if (!this.preorder) {
                this.$store.dispatch('getCart');
            }


            if (this.userIsLoggedIn) {
                let client = this.$store.getters.getUserInfo;
                this.wallet = client.monetary_wallet;
            }


            if (this.getCart != null) {
                if (this.getCart.couponCode != null) {
                    this.voucherCode = this.getCart.couponCode;
                    this.validateVoucher();
                }
            }


            EventBus.$on('sendOrderEvent', this.sendOrder);
            EventBus.$on('userLoggedIn', this.reinitializeWallet);

        },
        computed: {
            isInDebt() {
                if (this.wallet != null) {
                    return this.wallet[this.getCart.currency].balance < 0;
                } else {
                    return false;
                }
            },
            walletMessage() {
                let message = '';

                if (this.wallet != null) {
                    if (this.wallet[this.getCart.currency].balance < 0) {
                        message = 'تسویه کیف پول';
                    } else {
                        message = 'کسر از کیف پول';
                    }
                }


                return message;
            },
            paymentMethodURL() {
                return this.getCart.currency == 'dollar' ? require('../../assets/paypalPaymentIcon.png') : require('../../assets/zarinpalPayment.png');

            },
            itemsArray() {
                let data = [];
                let keys = Object.keys(this.cartItems);
                keys.forEach(key => {
                    data.push(this.cartItems[key])
                });

                return data;

            },
            canUseWallet() {
                // console.log(this.userIsLoggedIn);
                // console.log(this.wallet);
                return this.userIsLoggedIn && this.wallet != null && this.wallet[this.getCart.currency].balance != 0
            },
            invoiceItems() {
                return [
                    {class: '', title: 'جمع کل', price: this.totalPrice, show: true},
                    {
                        class: '',
                        title: this.walletMessage,
                        price: this.absNum(this.differencialPrice),
                        show: this.applyMonetaryWallet && this.canUseWallet
                    },
                    {
                        class: '',
                        title: 'تخفیف',
                        price: -this.getCart.sumDiscount,
                        show: this.getCart.sumDiscount != null && this.getCart.sumDiscount > 0
                    },
                    {class: '', title: 'مبلغ نهایی', price: this.finalPrice, show: true, final: true},
                ]
            },
            differencialPrice() {
                return this.getCart.finalPrice - this.finalPriceWithWallet;
            },
            finalPriceWithWallet() {

                if (this.wallet != null) {
                    if (this.getCart.currency == 'dollar') {
                        if (this.wallet.dollar.balance > 0) {
                            if (this.wallet.dollar.balance >= this.getCart.finalPrice) {
                                return 0;
                            } else {
                                let amount = this.getCart.finalPrice - this.wallet.dollar.balance;
                                return amount.toFixed(2);
                            }
                        } else if (this.wallet.dollar.balance < 0) {
                            let amount = this.getCart.finalPrice - this.wallet.dollar.balance;
                            return amount.toFixed(2);
                        } else {
                            return this.getCart.finalPrice;
                        }
                    } else {

                        if (this.wallet.rial.balance > 0) {
                            if (this.wallet.rial.balance >= this.getCart.finalPrice) {
                                return 0;
                            } else {
                                let amount = this.getCart.finalPrice - this.wallet.rial.balance;
                                return amount.toFixed(0);
                            }
                        } else if (this.wallet.rial.balance < 0) {
                            let amount = this.getCart.finalPrice - this.wallet.rial.balance;
                            return amount.toFixed(2);
                        } else {
                            return this.getCart.finalPrice;
                        }
                    }

                }


            },
            ...mapGetters(['getCart', 'loading', 'userIsLoggedIn']),
            cartItems() {
                return this.$store.getters.getCartItems;
            },
            calculateSum() {
                this.$store.dispatch('calculateSum', this.cartItems);
                return this.$store.getters.getSumPrice;
            },
            totalPrice() {
                if (this.getCart.currency == 'dollar') {
                    return this.getCart.totalPrice.toFixed(2);
                } else {
                    return this.getCart.totalPrice.toFixed(0);
                }
            },
            finalPrice() {
                if (this.applyMonetaryWallet && this.canUseWallet) {
                    return this.finalPriceWithWallet;
                } else {
                    if (this.getCart.currency == 'dollar') {
                        return this.getCart.finalPrice.toFixed(2);
                    } else {
                        return this.getCart.finalPrice.toFixed(0);
                    }
                }
            }
        },
        methods: {
            absNum(input) {
                return Math.abs(input);
            },
            voucherCleared() {
                this.validVoucher = 'unknown';
                this.message = '';
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('validateCoupon', {
                    'couponCode': ''
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                });
            },
            reinitializeWallet() {
                let client = this.$store.getters.getUserInfo;
                this.wallet = client.monetary_wallet;
            },
            showOfflinePayment() {
                if (this.privacyPolicy) {
                    this.showOfflinePaymentDialog = true;
                } else {
                    EventBus.$emit('notify', 'red', undefined, 'پذیرش شرایط و ضوابط الزامی می باشد');
                }
            },
            savingOfflineDone() {
                this.showOfflinePaymentDialog = false;
                let message = 'با موفقیت به ثبت رسید';
                if (this.preorder) {
                    message = 'با موفقیت به ثبت رسید، به صفحه داشبورد منتقل میشوید';

                    setTimeout(() => {
                        this.$router.push({name: 'dashboard', params: {lang: 'fa'}});
                    }, 2000)
                }

                EventBus.$emit('notify', 'green', undefined, message);
            },
            addToCart(item) {
                // this.loading = true;
                EventBus.$emit('setLoading', true);


                // let ecommerce = {
                //     purchase: {
                //         actionField: {
                //             id: 'test',
                //             revenue: '0' //<%=sales.total%>
                //         },
                //         products: []
                //     }
                // };
                // ecommerce.purchase.actionField.coupon = '';
                //
                // this.$gtm.trackEvent({
                //     event: 'eec.addToCart',
                //     ecommerce
                //     // noninteraction: false // Optional
                // });

                this.$store.dispatch('addToCart', {id: item.item.id}).then(() => {
                    // this.loading = false;
                    this.$gtm.trackEvent({
                        'event': 'eec.addToCart',
                        'ecommerce': {
                            'add': {
                                'products': [{
                                    'name': item.item.title,
                                    'id': item.item.id,
                                    'price': item.price,
                                    'brand': 'Simiaroom',
                                    'category': 'Counseling',
                                    // 'variant': 'Gray',
                                    'quantity': 1
                                }]
                            }
                        }
                    });

                }).finally(() => {
                    // this.loading = false;
                    EventBus.$emit('setLoading', false);
                })
            },
            destroyItem(item) {
                // this.loading = true;
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('cartRemoveItemAll', {
                    package_id: item.item.id

                }).then(() => {
                    // this.loading = false;
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                });
            },
            removeItem(item) {
                // this.loading = true;
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('cartRemoveItem', {
                    package_id: item.item.id

                }).then(() => {
                    // this.loading = false;
                    this.$gtm.trackEvent({
                        'event': 'eec.removeFromCart',
                        'ecommerce': {
                            'remove': {                               // 'remove' actionFieldObject measures.
                                'products': [{
                                    'name': item.item.title,
                                    'id': item.item.id,
                                    'price': item.price,
                                    'brand': 'Simiaroom',
                                    'category': 'Counseling',
                                    // 'variant': 'Gray',
                                    'quantity': 1
                                }]
                            }

                        }
                    });
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                });
            },
            validateVoucher(event) {

                if (this.voucherCode == "" && this.voucherCode == null || !this.voucherCode.length) {
                    EventBus.$emit('notify', 'red', undefined, 'کد خفیف را وارد نمایید');
                    return;
                }
                // let code = event.keyCode;
                // console.log(code)
                // if ((code >= 48 && code <= 57) || (code >= 65 && code <= 90) || (code >= 96 && code <= 105) || (code == 109) || (code == 189)) {

                // clearTimeout(this.voucherTimer);

                // this.voucherTimer = setTimeout(() => {
                this.requesting = true;
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('validateCoupon', {
                    'couponCode': this.voucherCode
                }).then(resp => {
                    // alert(resp.data.status);
                    if (resp.data.status == 'invalid') {
                        this.validVoucher = 'invalid';
                        this.message = 'کد نامعتبر می باشد';
                    } else if (resp.data.status == 'valid') {
                        this.message = 'با موفقیت اعمال شد';
                        this.validVoucher = 'valid';
                        this.voucher = resp.data;
                    }
                }).finally(() => {
                    this.requesting = false;
                    EventBus.$emit('setLoading', false);
                });
                // }, 500);
                // } else {
                //     console.log('Invalid Btn');
                // }
            },
            voucherPrice: function () {
                let amount = this.$store.getters.getSumPrice;
                let percent = this.voucher.percent / 100;
                let value = this.voucher.value;
                let price = (percent * amount) + value;
                return price.toFixed(2);
            },
            allData() {
                const cart = this.$store.getters.getCartItems;

                let products = [];
                let data = {};
                cart.forEach((item) => {
                    products.push(
                        {
                            'id': item._id,
                            'quantity': item.qty
                        }
                    );
                });

                data.products = products;
                data.voucher = this.voucherCode;
                data.totalPrice = this.$store.getters.getSumPrice;
                data.finalPrice = this.finalPrice;
                console.log('data', data);
                return data;
            },
            sendOrder() {

                let products = [];
                Object.keys(this.getCart.items).forEach((key) => {
                    let item = this.getCart.items[key];
                    products.push({
                        'name': item.item.title,
                        'id': item.item.id,
                        'price': item.price,
                        'brand': 'Simiaroom',
                        'category': 'Counseling',
                        // 'variant': 'Gray',
                        'quantity': item.qty
                    })
                })


                if (this.privacyPolicy) {

                    // this.loading = true;
                    EventBus.$emit('setLoading', true);
                    this.$store.dispatch('sendOrder', {
                        couponCode: this.voucherCode,
                        applyWallet: this.applyMonetaryWallet
                    }).then((data) => {
                        this.$gtm.trackEvent({
                            'event': 'eec.checkout',
                            'ecommerce': {
                                'checkout': {
                                    'actionField': {'step': 1, 'option': 'Submit'},
                                    products
                                }
                            }
                            // ,
                            // 'eventCallback': function () {
                            //
                            // }
                        });
                        this.$nextTick().then(() => {
                            window.location = data.data.url;
                        });

                        // this.loading = false;
                    }).catch(() => {
                        // this.loading = false;
                        this.$emit('finishedSendingOrder');
                        EventBus.$emit('setLoading', false);
                    }).finally(() => {

                        // this.loading = false;
                    })

                } else {
                    EventBus.$emit('notify', 'red', undefined, 'پذیرش شرایط و ضوابط الزامی می باشد');
                }
            }
        },
        watch: {
            getCart() {
                if (this.getCart != null) {
                    if (this.getCart.couponCode != null) {
                        this.voucherCode = this.getCart.couponCode;
                    }
                }
            },
            preorderCoupon() {
                this.voucherCode = this.preorderCoupon;
                // if (this.userIsLoggedIn) {
                //     this.voucherCode = this.preorderCoupon;
                //     this.validateVoucher();
                // }
            },
            userIsLoggedIn(newVal) {
                if (this.preorderCoupon != null && newVal) {
                    this.validateVoucher();
                }
            },
            // voucherCode() {
            //     if (this.userIsLoggedIn && this.voucherCode.length == 0) {
            //         this.validateVoucher();
            //     }
            // }
        },
        filters: {

            toDollar(val) {
                return `$ ${Number(val).toFixed(2)}`;
            },
            toRial(val) {
                return ` تومان ${Number(val).toFixed(0)}`;
            },
            withCurrency(val, currency) {
                if (currency == 'dollar') {
                    return `$ ${Number(val).toFixed(2)}`;
                } else {
                    let x = Number(val).toFixed(0);
                    return ` تومان ${Number(x).toLocaleString()}`;
                }
            }

        }
    }
</script>

<style scoped>
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .empty-cart-img {
        background-size: cover;
        background-position: center;
        height: 40vh;
    }

    .cart {
        display: flex;
        flex-direction: column;
    }

    .absolute-payment-img {
        position: absolute;
        left: 10px;
    }

    .final-price-item {
        border-top: .5px solid rgba(0, 0, 0, .4);
    }
</style>