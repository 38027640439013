<template>
    <div>
        <v-toolbar-items v-if="showTopMenu">
            <a class="py-5 px-4 link-to-website" href="https://www.simiaroom.com">سیمیاروم</a>
            <a class="py-5 px-4 link-to-website" href="https://simiaroom.com/counseling/">حوزه های مشاوره</a>
            <a class="py-5 px-4 link-to-website" href="https://simiaroom.com/counselor/">مشاوران</a>
            <a class="py-5 px-4 link-to-website" href="https://simiaroom.com/blog/">محتوای آموزشی</a>
            <a class="py-5 px-4 link-to-website"
               href="https://simiaroom.com/%d8%af%d8%b1%d8%a8%d8%a7%d8%b1%d9%87-%d9%85%d8%a7/">درباره
                ما</a>
            <a class="py-5 px-4 link-to-website" href="https://simiaroom.com/%d8%aa%d9%85%d8%a7%d8%b3-2/">تماس با ما</a>
            <!--<v-btn class="py-5" text :to="{name:'assignconsult'}">{{$vuetify.lang.t('$vuetify.navََََََََََََََAssignConsult')}}</v-btn>-->
        </v-toolbar-items>

        <v-list v-else-if="showNavIcon" id="navIconItems">
            <v-list-item>
                <v-list-item-avatar>
                    <img
                            style="object-fit: cover"
                            :src="require('./../../assets/avatar-default.png')"/>
                </v-list-item-avatar>

                <v-list-item-title>
                    <v-btn text class="font-weight-bold" :to="{name:'profile'}">{{ $store.getters.getUserInfo.firstname
                        + ' ' + $store.getters.getUserInfo.lastname }}
                    </v-btn>
                    <p class="secondary--text px-4" style="font-size: .6rem">مشاهده پروفایل
                        <v-icon color="secondary" small>mdi-chevron-left</v-icon>
                    </p>
                </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-content class="py-0">
                    <v-btn text :to="{name:'dashboard'}">{{$vuetify.lang.t('$vuetify.navDashboard')}}</v-btn>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content class="py-0">
                    <v-btn text :to="{name:'sessions'}">{{$vuetify.lang.t('$vuetify.navSessionsLabel')}}</v-btn>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content class="py-0">
                    <v-btn text :to="{name:'calendar'}">{{$vuetify.lang.t('$vuetify.navCalendar')}}</v-btn>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content class="py-0">
                    <v-btn text :to="{name:'orders'}">{{$vuetify.lang.t('$vuetify.userOrders')}}</v-btn>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content class="py-0">
                    <v-btn text :to="{name:'products'}">{{$vuetify.lang.t('$vuetify.navProducts')}}</v-btn>
                </v-list-item-content>
            </v-list-item>
            <v-list-item class="d-flex align-content-end">
                <v-list-item-content class="py-0">
                    <v-btn text :to="{name:'assignconsult'}">
                        {{$vuetify.lang.t('$vuetify.navََََََََََََََAssignConsult')}}
                    </v-btn>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        computed: {
            showNavIcon() {
                // return true;
                return this.$vuetify.breakpoint.mdAndDown;
            },
            showTopMenu() {
                return this.$vuetify.breakpoint.mdAndUp;
            }
        }
    }
</script>

<style>
    #navIconItems .v-btn__content {
        justify-content: right;
    }

    .link-to-website {
        text-decoration: none;
        color: rgba(30, 30, 30, 1)!important;
    }
</style>