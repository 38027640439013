<template>
    <v-card class="pa-2">
        <v-card-text>

            <v-window v-model="offlinePaymentWindow">
                <v-window-item :value="1">
                    <v-card class="elevation-0">

                        <v-card-title>پرداخت آفلاین</v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>

                            <div>متد پرداخت خود را انتخاب کنید</div>
                            <v-list dense>

                                <v-list-item v-for="(method) in offlineCartCurrencyMethods" :key="method.id"
                                             @click="chooseOfflineMethod(method)">
                                    <v-list-item-content>{{method.title}}
                                    </v-list-item-content>

                                </v-list-item>

                            </v-list>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="$emit('close')" class="red white--text">بستن</v-btn>
                        </v-card-actions>
                    </v-card>

                </v-window-item>
                <v-window-item :value="2">
                    <v-card class="elevation-0">
                        <v-card-title v-if="paymentMethod!=null"> ثبت پرداخت {{paymentMethod.title}}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-form ref="addOfflinePaymentForm">

                                <v-row>
                                    <v-col cols="12">
                                        <v-card v-if="paymentMethod.image!=null">
                                            <v-img :src="paymentMethod.image"></v-img>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <p>
                                            هزینه بسته مشاوره خود را به حساب فوق واریز نمایید و پس از پرداخت، اطلاعات
                                            پرداخت را در فیلد های زیر وارد نمایید، و منتظر تایید بخش مالی بمانید.
                                        </p>
                                        <!--<v-col v-if="getCart.currency != 'rial' && paymentMethod.code != 'USD'"-->
                                        <!--class="text-center">-->
                                        <!--برای تبدیل مبلغ به نرخ ارز {{paymentMethod.code}} از طریق <a-->
                                        <!--:href="`${convertLink}`" target="_blank">این لینک</a> اقدام نمایید.-->
                                        <!--</v-col>-->
                                    </v-col>
                                </v-row>
                                <v-row>

                                    <v-col cols="12" md="4" lg="4" sm="6">
                                        <v-text-field
                                                outlined
                                                label="کد پیگیری"
                                                v-model="payment.transaction_code"

                                        >

                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="4" lg="4" sm="6">
                                        <!--:value="`${getCart.currency == 'dollar' ? '$' : 'تومان'} ${Number(Number(getCart.finalPrice).toFixed(2)).toLocaleString()}`"-->
                                        <v-text-field
                                                v-if="convertedCart!=null"
                                                class="mx-3"
                                                :value="convertedCart.remaining_amount"
                                                label="مبلغ نهایی:"
                                                readonly
                                                disabled
                                                outlined
                                        >
                                            <template v-slot:append>
                                                <span class="black--text">
                                                    {{convertedCart.currency|resolveCurrency}}
                                                </span>
                                            </template>
                                        </v-text-field>

                                    </v-col>


                                    <v-col cols="12" md="4" lg="4" sm="6">
                                        <!--<v-dialog-->
                                        <!--ref="dialog"-->
                                        <!--v-model="modal"-->
                                        <!--:return-value.sync="payment.date"-->
                                        <!--persistent-->

                                        <!--width="290px"-->
                                        <!--&gt;-->
                                        <!--<template v-slot:activator="{ on, attrs }">-->
                                        <!--<v-text-field-->
                                        <!--outlined-->
                                        <!--v-model="payment.date"-->
                                        <!--label="تاریخ پرداخت"-->
                                        <!--:rules="requiredRules"-->
                                        <!--prepend-icon="event"-->
                                        <!--v-bind="attrs"-->
                                        <!--readonly-->
                                        <!--v-on="on"-->
                                        <!--&gt;</v-text-field>-->
                                        <!--</template>-->
                                        <!--<v-date-picker v-model="payment.date" scrollable>-->
                                        <!--<v-spacer></v-spacer>-->
                                        <!--<v-btn text color="primary" @click="modal = false">بستن</v-btn>-->
                                        <!--<v-btn text color="primary" @click="$refs.dialog.save(payment.date)">-->
                                        <!--تایید-->
                                        <!--</v-btn>-->
                                        <!--</v-date-picker>-->
                                        <!--</v-dialog>-->

                                        <!--</template>-->
                                        <v-text-field
                                                outlined
                                                v-model="payment.date"
                                                label="تاریخ پرداخت"
                                                :rules="requiredRules"
                                                prepend-icon="event"

                                                readonly
                                                @click="modal=true"

                                        ></v-text-field>

                                        <AdminDatePicker @close="modal=false" :dialog="modal"
                                                         v-model="payment.date"></AdminDatePicker>
                                    </v-col>
                                    <v-col cols="12" md="4" lg="3" sm="6">
                                        <v-text-field
                                                outlined
                                                label="ارز پرداختی"
                                                readonly
                                                :value="payment.origin_currency | resolveCurrency"
                                                :rules="requiredRules"
                                        >

                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" lg="3" sm="6">
                                        <!--<v-text-field-->
                                        <!--outlined-->
                                        <!--label="مبلغ پرداختی"-->
                                        <!--v-model="payment.origin_amount"-->
                                        <!--:rules="requiredRules"-->
                                        <!--hint="مبلغ پرداختی شما در ارز مورد نظر"-->
                                        <!--&gt;-->
                                        <!--</v-text-field>-->

                                        <v-text-field
                                                v-if="isFocused"
                                                outlined
                                                label="مبلغ پرداختی"
                                                :rules="requiredRules"
                                                @blur="isFocused=false"
                                                v-model="payment.origin_amount"

                                        >
                                        </v-text-field>
                                        <v-text-field
                                                v-else-if="!isFocused"
                                                outlined
                                                label="مبلغ پرداختی"
                                                :rules="requiredRules"
                                                @focus="isFocused=true"
                                                :value="payment.origin_amount | toLocaleOrFixed"
                                                style="font-weight: bold"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                            </v-form>
                            <v-row>

                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn text small @click="offlinePaymentWindow = 1">
                                <v-icon small class="mx-1 grey--text">fas fa-long-arrow-alt-right</v-icon>
                                بازگشت به روشهای پرداخت

                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="$emit('close')" class="red white--text">بستن</v-btn>
                            <v-btn text class="primary" @click="sendOfflineOrder">ثبت پرداخت</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-window-item>
            </v-window>
        </v-card-text>
        <AlertsComponent></AlertsComponent>
    </v-card>
</template>

<script>
    /*eslint-disable*/
    import {mapGetters} from 'vuex';
    import AlertsComponent from '@/components/AlertsComponent';
    import {EventBus} from "../../event-bus";
    import AdminDatePicker from '@/components/AdminDatePicker';
    import {resolveCurrency} from "../../utilities";

    export default {
        components: {
            AlertsComponent,
            AdminDatePicker
        },
        props: {
            cart: {
                default: () => ({})
            },
            type: {
                default: null
            },
            show: {
                default: false
            },
            preorder: {
                default: false
            },
            coupon_code: {
                default: ''
            }
        },
        created() {
            this.getOfflinePaymentMethods();
        },
        methods: {
            chooseOfflineMethod(item) {

                this.paymentMethod = item;
                let payload = {
                    currency: item.code
                };
                if (this.type == null) {
                    this.$store.dispatch('userChangeCartCurrency', payload).then((resp) => {
                        this.convertedCart = resp.data;
                        this.payment.payment_method_id = item.id;
                        this.payment.origin_currency = item.code;
                        this.offlinePaymentWindow = 2;
                    });
                } else {
                    this.convertedCart = this.cart;
                    this.payment.payment_method_id = item.id;
                    this.payment.origin_currency = item.code;
                    this.offlinePaymentWindow = 2;
                }


            },
            sendOfflineOrder() {

                if (this.$refs.addOfflinePaymentForm.validate()) {
                    this.payment.couponCode = this.coupon_code;
                    EventBus.$emit('setLoading', true);

                    if (this.type == null) {
                        this.$store.dispatch('saveOpenCartOfflineOrder', this.payment).then(() => {

                            this.$store.dispatch('getCart');
                            this.$emit('done');

                        }).finally(() => {
                            EventBus.$emit('setLoading', false);
                        });
                    } else {

                        this.$store.dispatch('saveFactorOfflineOrder', {
                            factorId: this.cart.id,
                            payload: this.payment
                        }).then(() => {

                            this.$emit('done');

                        }).finally(() => {
                            EventBus.$emit('setLoading', false);
                        });
                    }


                }

            },

            getOfflinePaymentMethods() {
                this.paymentMethodsLoading = true;
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('userGetOfflinePaymentMethods').then((resp) => {
                    this.offlinePaymentMethods = resp.data;

                }).finally(() => {
                    this.paymentMethodsLoading = false;
                    EventBus.$emit('setLoading', false);
                })
            },
        },
        computed: {
            offlineCartCurrencyMethods() {

                if (this.type != null) {
                    return this.offlinePaymentMethods.filter((item) => item.code == this.cart.currency);
                } else {
                    return this.offlinePaymentMethods.filter((item) => item.currency == this.cart.currency);
                }

                // return this.offlinePaymentMethods;
            },
            convertLink() {
                let baseUrl = 'https://www.xe.com/currencyconverter/convert/?';

                return baseUrl + `Amount=${this.cart.finalPrice}&From=${this.cart.currency}&To=${this.paymentMethod.code}`;
            },
            ...mapGetters({
                currencies: 'getCurrencies'
            }),
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
        },
        filters: {
            toLocaleOrFixed(xx) {
                return Number(xx).toLocaleString();
            },
            resolveCurrency(input) {
                return resolveCurrency(input);
                // let result = '';
                // switch (input) {
                //     case 'rial':
                //         result = 'تومان';
                //         break;
                //     case 'dollar':
                //         result = 'دلار';
                //         break;
                //     case 'cad':
                //         result = 'دلار کانادا';
                //         break;
                //     case 'aud':
                //         result = 'دلار استرالیا';
                //         break;
                //     case 'euro':
                //         result = 'یورو';
                //         break;
                //     case 'gbp':
                //         result = 'پوند';
                //         break;
                // }
                // return result;
            }
        },
        data() {
            return {
                convertedCart: null,
                isFocused: false,
                paymentMethod: {},
                modal: false,
                // currencies: [
                //     {title: 'دلار', value: 'dollar'},
                //     {title: 'تومان', value: 'rial'}
                // ],
                paymentMethodsLoading: false,
                offlinePaymentMethods: [],

                payment: {
                    date: null,
                    couponCode: null,
                    transaction_code: '',
                    payment_method_id: null,
                    origin_amount: null,
                    origin_currency: null,
                },
                offlineMethods: [],
                offlinePaymentWindow: 1,
            }
        },
        watch: {
            show(newVal) {
                if (newVal) {
                    this.offlinePaymentWindow = 1;
                }
            }
        }
    }
</script>

<style scoped>

</style>