<template>
    <nav>
        <v-app-bar app class="elevation-2" color="white">
            <template v-if="showNavIcon">

                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

                <v-spacer></v-spacer>

                <router-link :to="{name:'login',params:{lang:'fa'}}" style="text-decoration: none">
                    <v-toolbar-title class="mr-12 " v-if="isSimia">
                        <v-img :src="logo" max-width="100"></v-img>
                    </v-toolbar-title>
                    <v-toolbar-title class="mr-12 font-weight-bold" v-else>
                        <div>مشاور لایو</div>
                    </v-toolbar-title>
                </router-link>
            </template>


            <template v-if="showTopMenu">
                <router-link :to="{name:'login',params:{lang:'fa'}}" style="text-decoration: none">
                    <v-toolbar-title class="mr-12 ml-12" v-if="isSimia">
                        <v-img :src="logo" max-width="100"></v-img>
                    </v-toolbar-title>
                    <v-toolbar-title class="mr-12 ml-12 font-weight-bold" v-else>
                        <div>مشاور لایو</div>
                    </v-toolbar-title>
                </router-link>
                <WordpressNavItems v-if="isSimia"></WordpressNavItems>

                <v-spacer></v-spacer>

                <template v-if="isLoggedin">
                    <div class="text-center">

                        <v-menu
                                v-if="getUserInfo!=null"
                                :close-on-content-click="false"
                                :nudge-width="200"
                                offset-x
                                offset-y
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn class="elevation-0 transparent mx-5" fab>
                                    <v-avatar>
                                        <img v-on="on"
                                             v-if="$store.getters.userHasRole('admin') || $store.getters.userHasRole('customer')"
                                             src='./../assets/avatar-default.png'/>
                                        <img
                                                v-else
                                                style="object-fit: cover"
                                                :src="getUserInfo.avatar ? getUserInfo.avatar : require('./../assets/avatar-default.png')"
                                                alt="avatar"
                                                v-on="on"
                                        >
                                    </v-avatar>
                                </v-btn>
                            </template>

                            <v-card>
                                <v-list>
                                    <v-list-item>

                                        <v-list-item-content>
                                            <v-list-item-title v-if="getUserInfo">{{
                                                getUserInfo.firstname + ' ' +
                                                getUserInfo.lastname }}
                                            </v-list-item-title>
                                        </v-list-item-content>

                                    </v-list-item>
                                </v-list>

                                <v-divider></v-divider>

                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn text rounded :to="{name:'profile',params:{lang:'fa'}}">حساب کاربری
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item>

                                        <v-list-item-title>
                                            <v-btn text rounded @click="logOut">خروج
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>

                            </v-card>
                        </v-menu>
                    </div>
                </template>
                <!--<div v-else>-->
                <!--<v-btn text class="mx-3" small :to="{name:'login',params:{lang:'fa'}}" dark>-->
                <!--<span class="black&#45;&#45;text">-->
                <!--{{$vuetify.lang.t('$vuetify.loginLabel')}} | {{$vuetify.lang.t('$vuetify.signUpLabel')}}-->
                <!--</span>-->
                <!--</v-btn>-->
                <!--</div>-->

            </template>
        </v-app-bar>

        <v-navigation-drawer
                v-model="drawer"
                temporary
                app
                :right="isRTL"
        >
            <default-nav-items v-if="!isLoggedin"></default-nav-items>
            <!--<client-nav-items v-if="$store.getters.userHasRole('customer') && isLoggedin"></client-nav-items>-->

            <template v-slot:append v-if="isLoggedin">
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-logout-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn text @click="logOut">خروج</v-btn>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-navigation-drawer>
    </nav>
</template>
<script>
    import WordpressNavItems from '@/components/navs/WordpressNavItems'
    import DefaultNavItems from "./navs/DefaultNavItems";
    import {mapGetters} from 'vuex';

    export default {
        props: ['isSimia'],
        components: {
            WordpressNavItems,
            DefaultNavItems,
        },
        data() {
            return {
                logo: require('../assets/logo2.png'),
                walletMenu: false,
                path: this.$route.name,
                drawer: false,
            };
        },
        computed: {
            ...mapGetters(['getUserInfo', 'getCart', 'getPlatform']),
            showNavIcon() {
                // return true;
                return this.$vuetify.breakpoint.smAndDown;
            },

            showTopMenu() {
                return this.$vuetify.breakpoint.mdAndUp;
            },

            isRTL() {
                return this.$store.getters.isRTL;
            },
            walletGlobalItems() {

                return this.getUserInfo.wallet.global_packages;
            },
            totalWalletItems() {

                return this.getUserInfo.wallet.total;
            },
            walletDoctorItems() {
                return this.getUserInfo.wallet.dr_packages;
            },
            cartItems() {
                return this.$store.getters.cartQuantity;

            },
            isLoggedin() {
                return this.$store.getters.userIsLoggedIn;
            }
        },
        created() {
            this.chooseLogo();
            // console.log(this.$store.state.userInfo);
        },
        watch: {},
        methods: {
            chooseLogo: function () {
                if (this.isSimia) {
                    this.logo = require('../assets/logo2.png')
                } else {
                    this.logo = require('../assets/logo2.png')
                }
            },
            logOut() {
                this.$store.dispatch('logOut').then(() => {
                    this.$router.push({name: 'login',params:{lang:'fa'}});
                });
            }
        }
    }
</script>
<style>
    .myBadge {
        position: absolute;
        top: -8px;
        right: -5px;
        background-color: red;
        width: 17px;
        height: 17px;
        line-height: 19px;
        text-align: center;
        border-radius: 30px;
        color: white;
    }
</style>